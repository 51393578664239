@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
}

body {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

div[data-lastpass-icon-root] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat, sans-serif;
}

div,
p {
  font-family: Lato, sans-serif;
}
